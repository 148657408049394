import React from "react"
import { Link } from "gatsby"
import hippoMakesLogo from "../images/hippo-logo.png"
import lightBulb from "../images/our-values/Light-bulb.png"
import collaboration from "../images/our-values/Collaboration.png"
import technology from "../images/our-values/Technology@2x.png"
import nature from "../images/our-values/Nature.png"
import economy from "../images/our-values/Economy@2x.png"
import { NewsletterSingupModal } from "../components/newsletter-signup-modal/NewsletterSignupModal"

const OUR_VALUES = [
  {
    img: lightBulb,
    width: 85,
    textLine: { first: "We believe in you and your ideas.", second: "We empower you." }
  },
  {
    img: collaboration,
    width: 143,
    textLine: { first: "We believe in the power of collaboration.", second: "We enable it." }
  },
  {
    img: technology,
    width: 100,
    textLine: { first: "We love exponential technology.", second: "We bring them to the point-care." }
  },
  {
    img: nature,
    width: 100,
    textLine: { first: "We love our nature.", second: "Together we reduce the carbon footprint." }
  },
  {
    img: economy,
    width: 100,
    textLine: { first: "We love a strong, local economy.", second: "We foster open innovation." }
  }
]

const IndexPage = () => {
  return (
    <>
      <div className="container-xl">
        <div className="row justify-content-center" style={{ marginTop: 120 }}>
          <img src={hippoMakesLogo} alt="Hippo Makes Logo" width="158" height="142"/>
        </div>
        <h1 id="brand-label" className="text-center">Hippo Makes</h1>
        <h3 id="brand-sub-label" className="text-center">We enable medical innovators</h3>
        <div className="row d-flex flex-column align-items-center">
          <article>
            <p className="text-center">
              HIPPO MAKES is building a global collaborative community for medical digital manufacturing. Accelerating
              the
              adoption of digital manufacturing technologies for the medical industry.<br/>
              <br/>
              You want to get started as soon as possible?<br/>
              Stay informed and subscribe to our news.
            </p>
          </article>
          <NewsletterSingupModal/>
        </div>
        <div id="our-values-headline" className="row justify-content-center align-items-end">
          <div id="red-box"/>
          <h3 className="d-inline-block">Our values</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div id="our-values-content" className="row justify-content-center">
          {OUR_VALUES.map(({ img, width, textLine: { first, second } }, i) => (
            <div className="col-md-4 our-values-item" key={i}>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="row justify-content-center align-items-center" style={{ height: 100 }}>
                    <img src={img} className="img-fluid" alt="value icon" width={width}/>
                  </div>
                  <p className="text-center value-text">
                    {first}<br/>
                    {second}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <footer className="container-fluid">
        <h3 id="footer-brand">
          Hippo Makes
        </h3>
        <p>
          We enable medical innovators
        </p>
        <div id="footer-divider"/>
        <div id="footer-row" className="row">
          <p>@2020 HippoMakes
            <Link to="/disclaimer/" className="ml-4">
              Imprint
            </Link>
          </p>
        </div>
      </footer>
    </>
  )
}

export default IndexPage
