import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Form from "react-bootstrap/Form"

const url = "https://hippomakes.us8.list-manage.com/subscribe/post?u=d5103d936f07b036ae5654dfd&id=1f7ad92f2b"

const CustomForm = ({ status, message, onValidated, onCloseClick }) => {
  const [validated, setValidated] = useState(false)
  const [checkboxesValid, setCheckboxesValid] = useState(true)
  let email, fname, lname, gdpr26669, gdpr26673, gdpr26677

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setValidated(true)
    setCheckboxesValid(gdpr26669.checked || gdpr26673.checked || gdpr26677.checked)

    email &&
    fname &&
    email.value.indexOf("@") > -1 &&
    (gdpr26669.checked || gdpr26673.checked || gdpr26677.checked) &&
    onValidated({
      EMAIL: email.value,
      FNAME: fname.value,
      LNAME: lname.value,
      "gdpr[26669]": gdpr26669.checked ? gdpr26669.value : "N",
      "gdpr[26673]": gdpr26673.checked ? gdpr26673.value : "N",
      "gdpr[26677]": gdpr26677.checked ? gdpr26677.value : "N"
    })
  }

  return (
    <div className="container-fluid">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {status === "sending" && <div>Sending...</div>}
        {status === "error" && (
          <div className="alert alert-danger mt-3 mb-5">
            <span dangerouslySetInnerHTML={{ __html: message }}/>
          </div>
        )}
        {status === "success" && (
          <div className="alert alert-success mt-3 mb-5">
            <span dangerouslySetInnerHTML={{ __html: message }}/>
          </div>
        )}
        {(!status || status === "error") && (
          <>
            <div className="row justify-content-end">
              <span className="mt-3 mb-4" style={{ fontSize: 12 }}><span style={{ color: "#D50000" }}>*</span> indicates required</span>
            </div>
            <Form.Group controlId="email-input">
              <Form.Label>Email Address <span style={{ color: "#D50000" }}>*</span></Form.Label>
              <Form.Control
                ref={node => (email = node)}
                type="email"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="fname-input">
              <Form.Label>First Name <span style={{ color: "#D50000" }}>*</span></Form.Label>
              <Form.Control
                ref={node => (fname = node)}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please state your fist name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lname-input">
              <Form.Label>Last Name <span style={{ color: "#D50000" }}>*</span></Form.Label>
              <Form.Control
                className="form-control"
                ref={node => (lname = node)}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please state your last name
              </Form.Control.Feedback>
            </Form.Group>

            <p className="signup-text mt-5" style={{ color: "black" }}><strong>Marketing Permissions</strong></p>
            <p className="signup-text mt-4">Please select all the ways you would like to hear from HIPPO MAKES:</p>
            <Form.Group>
              <Form.Check
                label="Email"
                name="gdpr[26669]"
                value="Y"
                ref={node => (gdpr26669 = node)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Direct Mail"
                name="gdpr[26673]"
                value="Y"
                ref={node => (gdpr26673 = node)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Customized Online Advertising"
                name="gdpr[26677]"
                value="Y"
                ref={node => (gdpr26677 = node)}
              />
              {!checkboxesValid && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please select at least one of the three items
                </div>
              )}
            </Form.Group>
            <p className="signup-text">You can unsubscribe at any time by clicking the link in the footer of our emails.
              For information about our
              privacy practices, please visit our website.</p>
            <p className="signup-text">We use Mailchimp as our marketing platform. By clicking below to subscribe, you
              acknowledge that your
              information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/"
                                                                              target="_blank" rel="noopener noreferrer">
                Learn more about Mailchimp's privacy practices here.</a></p>
            <div className="row justify-content-center mt-5 mb-4">
              <Button type="submit" variant="primary">
                Subscribe
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}

export const NewsletterSingupModal = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button onClick={handleShow} id="newsletter-subscribe-button" variant="primary">
        Stay Informed
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <h3 className="text-center mt-4" style={{ width: "100%", marginLeft: "1rem" }}>Subscribe</h3>
        </Modal.Header>
        <Modal.Body>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                onCloseClick={handleClose}
              />
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
